import * as emailjs from 'emailjs-com';
import { Fade, Slide } from "react-reveal";
import React, { Component } from "react";
import { Field, Control, Input, Button, Textarea} from 'rbx';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, subject, message } = this.state;
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: '/*YOUR NAME OR COMPANY*/',
      subject,
      message_html: message,
    };
    emailjs.send(
      'service_0ug4ss8',
      'template_nbw0q2p',
       templateParams,
      'user_UBNb4OvlTfjeuZjgpOaW8'
    )
    this.resetForm();
  };

  resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    if (!this.props.data) return null;

    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message1 = this.props.data.contactmessage;
    const wechat = "https://unpkg.zhimg.com/react-nice-resume@1.0.3/images/" + this.props.data.image1;
    const { name, email, subject, message } = this.state;


    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>保持联系</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message1}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">

      <form onSubmit={this.handleSubmit}>
        <Field>
        <Control>
          <label htmlFor="contactName">姓名 <span className="required">*</span></label>
            <Input
              id="contactName"
              name="name"
              type="text"
              placeholder=""
              value={name}
              onChange={this.handleChange}
            />
          </Control>
        </Field>
        <Field>
        <Control>
          <label htmlFor="contactEmail">
            邮箱 <span className="required">*</span>
          </label>
            <Input
              id="contactEmail"
              name="email"
              type="email"
              placeholder=""
              value={email}
              onChange={this.handleChange}
            />
          </Control>
        </Field>
        <Field>
        <Control>
          <label htmlFor="contactSubject">主题</label>
            <Input
              id="contactSubject"
              name="subject"
              type="text"
              placeholder=""
              value={subject}
              onChange={this.handleChange}
            />
          </Control>
        </Field>
        <Field>
        <Control>
          <label htmlFor="contactMessage">
            留言 <span className="required">*</span>
          </label>
            <Textarea
              id="contactMessage"
              name="message"
              placeholder=""
              value={message}
              onChange={this.handleChange}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control>
            <Button  className="submit" color="dark">提交</Button>
          </Control>
        </Field>
</form>

</div>
</Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>地址和电话</h4>
                <p className="address">
                  {state} {city} {street} {zip}
                  <br />
                  <span>{phone}</span>
                </p>
              </div>

              <div className="widget widget_tweets">
                <h4 className="widget-title">在线沟通</h4>
                <ul id="twitter">
                  <li>
                    <img
                className="profile-pic"
                src={wechat}
                width="240" height="240"
                alt="Profile Pic"
              />
                  </li>
                </ul>
              </div>
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;